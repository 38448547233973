import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';
import withThemeSwitcher from 'core/hocs/withThemeSwitcher';
import withTheme from 'core/hocs/withTheme';

import { Block, Section } from 'core/components/Grid';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';
import ListDivider from 'site/components/ListDivider';

import {
  CardSmall2,
  CardSmall3,
} from 'site/cards/CardSmall';
import CardMainHero, {
  CardMainHeroS,
} from 'site/cards/CardMainHero';

import {
  SIDE_INDENT_MOBILE,
  SIDE_INDENT,
} from 'site/constants';

import styles from './index.styl';


const MobileCard = withBindProps({
  card: CardSmall2,
  indent: 15,
})(ListDivider);

const HOR_SPACING = 60;
const VERT_SPACING = 25;


function DarkTop(props) {
  const {
    content,
    isMobile,
    theme,
  } = props;

  if (!content || !content.length) return null;

  if (isMobile) {
    return (
      <div className='wrapper'>
        <style jsx>{`
          .wrapper
            padding 15px ${SIDE_INDENT_MOBILE}px 39px
            background ${theme.colors.layout}
        `}</style>
        <Feed
          content={content}
          heroCard={CardMainHeroS}
          card={MobileCard}
        />
      </div>
    );
  }

  const [
    heroTopic,
    second,
    third,
    fourth,
    fifth,
    sixth,
    seventh,
    eighth,
    ninth,
  ] = content;

  return (
    <div className={styles.desktopWrapper}>
      <style jsx>{`
        .${styles.desktopWrapper}
          padding 20px ${SIDE_INDENT}px
          background ${theme.colors.content}
      `}</style>
      <Section>
        <Block>
          <CardMainHero content={heroTopic} />
        </Block>
        <Block width={`${HOR_SPACING}px`} />
        <Block>
          <Section>
            <Block><CardSmall3 content={fourth} /></Block>
            <Block width={`${HOR_SPACING}px`} />
            <Block><CardSmall3 content={seventh} /></Block>
          </Section>
          <Indent bottom={VERT_SPACING} />
          <Section>
            <Block><CardSmall3 content={fifth} /></Block>
            <Block width={`${HOR_SPACING}px`} />
            <Block><CardSmall3 content={eighth} /></Block>
          </Section>
        </Block>
      </Section>
      <Indent bottom={VERT_SPACING} />
      <Section>
        <Block><CardSmall3 content={second} /></Block>
        <Block width={`${HOR_SPACING}px`} />
        <Block><CardSmall3 content={third} /></Block>
        <Block width={`${HOR_SPACING}px`} />
        <Block><CardSmall3 content={sixth} /></Block>
        <Block width={`${HOR_SPACING}px`} />
        <Block><CardSmall3 content={ninth} /></Block>
      </Section>
    </div>
  );
}

DarkTop.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withThemeSwitcher('dark')(withBreakpoint(withTheme(DarkTop)));
