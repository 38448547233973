import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withBreakpoint from 'core/hocs/withBreakpoint';

import FadedDesktop from './Desktop';
import FadedMobile from './Mobile';


function Faded(props) {
  const {
    content,
    isDesktop,
  } = props;

  if (!content || !content.length) return null;

  content.forEach((topicData, i) => {
    if (i > 0) {
      delete topicData.relationships.image;
      delete topicData.relationships.rubric;
    }
  });

  return isDesktop
    ? <FadedDesktop content={content} />
    : <FadedMobile content={content} />;
}

Faded.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Faded);
