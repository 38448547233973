import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';

import resolveRelationships from 'core/utils/relationships';

import Link from 'core/components/Link';
import TopicFooter from 'core/components/TopicFooter';
import { Indent } from 'core/components/Wrappers';

import {
  SuperFooter,
} from 'site/components/Ads/desktop';
import Sponsored from 'site/components/Ads/Sponsored';
import AdWrapper from 'core/components/Ad/AdWrapper';

const relationships = resolveRelationships(...[
  ['tags'],
  {},
  { tags: [] },
]);

function TopicFooterWrapper(props) {
  const {
    content,
    isDesktop,
    theme,
    hideSuperFooter,
  } = props;

  const {
    controls: {
      topicFooter: {
        block: {
          spacing,
        },
      },
    },
  } = theme;

  const {
    tags,
  } = relationships(content);

  const invest = tags.filter(tag => tag.attributes.slug === 'investicii');

  return (
    <Fragment>
      <style jsx>{`
        .invest
          font italic 12px ${theme.fonts.text}
        `}</style>

      {!!invest.length &&
        <Indent bottom={spacing} >
          <div className='invest'>
            <p>
              Материал не является индивидуальной инвестиционной рекомендацией.
              Упомянутые финансовые инструменты или операции могут не соответствовать вашему инвестиционному
              профилю и инвестиционным целям/ожиданиям. Определение соответствия финансового инструмента/операции/продукта
              вашим интересам, целям, инвестиционному горизонту и уровню допустимого риска — исключительно ваша задача.
            </p>
            <p>
              <Link
                to='https://secretmag.ru/'
                type='secondary'
              >
                Редакция «Секрета фирмы»&nbsp;
              </Link>
              не несёт ответственности за возможные убытки в
              случае совершения операций либо инвестирования в финансовые инструменты, упомянутые в данном материале.
              И не рекомендует использовать эту информацию в качестве единственного источника при принятии инвестиционного решения.
            </p>
          </div>
        </Indent>
      }
      <Sponsored />

      <Indent bottom={spacing} >
        <TopicFooter
          content={content}
          hrefBuilder={(slug) => (
            `/label/${slug}`
          )}
        />
      </Indent>

      {isDesktop && !hideSuperFooter && (
        <AdWrapper top={spacing}>
          <SuperFooter />
        </AdWrapper>
      )}
    </Fragment>
  );
}

TopicFooterWrapper.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  hideSuperFooter: PropTypes.bool,
};

export default withBreakpoint(withTheme(TopicFooterWrapper));
