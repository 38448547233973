import cx from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';
import withTheme from 'core/hocs/withTheme';

import Scroller from 'core/components/Scroller';
import Link from 'core/components/Link';

import { Indent } from 'site/components/Wrappers';

import styles from './index.styl';


const tags = [
  {
    title: 'Все',
    slug: '',
  },
  {
    title: 'Экономика',
    slug: 'ekonomika',
  },
  {
    title: 'Бизнес',
    slug: 'business',
  },
  {
    title: 'Финансы',
    slug: 'finansy',
  },
  {
    title: 'Технологии',
    slug: 'tekhnologii',
  },
  {
    title: 'Закон',
    slug: 'zakon',
  },
  {
    title: 'Государство',
    slug: 'gosudarstvo',
  },
  {
    title: 'Люди',
    slug: 'lyudi',
  },
  {
    title: 'Компании',
    slug: 'kompanii',
  },
  {
    title: 'Организации',
    slug: 'organizatsii',
  },
  {
    title: 'Повседневность',
    slug: 'povsednevnost',
  },
  {
    title: 'Здоровье',
    slug: 'zdorove',
  },
  {
    title: 'Явление',
    slug: 'yavlenie',
  },
];


function Tags(props) {
  const {
    disabled,
    theme,
    location: {
      pathname,
      search,
    },
  } = props;

  const {
    page, // eslint-disable-line no-unused-vars
    ...queryParamsWithoutPage
  } = queryString.parse(search);

  return (
    <Indent top={25} bottom={20}>
      <Scroller>
        <div
          className={cx(
            styles.tagsWrapper,
            disabled && styles._disabled,
          )}
        >
          <style jsx>{`
            .title
              font 700 16px/19px ${theme.fonts.display}
              :global(.mobile) &
                font 14px/17px ${theme.fonts.display}

            :global(.${styles.link})
              border 1px solid ${theme.colors.grey4}
              background-color ${theme.colors.content}
              color ${theme.colors.active1}
              &:not(.${styles.active}):hover
                background-color ${theme.colors.grey}

            :global(.${styles.active})
              background-color ${theme.colors.primary}
              color ${theme.colors.content} !important
              border none
            `}</style>
          <div className='title'>Теги:</div>
          <ul className={styles.tags}>
            {tags.map(tag => {
              const {
                title,
                slug,
              } = tag;
              const isAll = tag.slug === '';
              const link = slug === ''
                ? '/enciklopediya'
                : `${pathname}?${queryString.stringify({ ...queryParamsWithoutPage, search: slug })}`;
              const active = (queryParamsWithoutPage.search === tag.slug) || (isAll && (queryParamsWithoutPage.search === undefined));
              return (
                <li key={slug} className={styles.listItem}>
                  <Link
                    to={link}
                    type='primary'
                    className={cx(
                      styles.link,
                      active && styles.active,
                    )}
                  >
                    {!isAll && <span className={styles.hash}>#&nbsp;</span>}
                    <span className='title'>{title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </Scroller>
    </Indent>
  );
}

Tags.propTypes = {
  disabled: PropTypes.bool,
  theme: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(withTheme(Tags));
