import PropTypes from 'prop-types';

import { Section, Block } from 'core/components/Grid';
import Divider from 'core/components/Divider';
import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import FullWidth from 'core/components/FullWidth';
import Scroller from 'core/components/Scroller';

import { Mobile, Desktop } from 'core/components/breakpoint';
import withTheme from 'core/hocs/withTheme';

import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import {
  CardVerticalL,
  CardVerticalS,
} from 'site/cards/CardVertical';

import styles from './index.styl';

const link = {
  url: '/label/video',
  title: 'Другие видео',
};


const FooterTop = (props) => {
  const {
    content,
    theme,
  } = props;

  if (!content || !content.length) return null;

  const { indents } = theme.layout;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.scroller}
          margin-left 0

        .scrollerList
          padding-right ${indents.right}px
      `}</style>
    </scope>
  );

  return (
    <FullWidth className='footerTop' ignoreLayoutIndents>
      <style jsx>{`
        :global(.footerTop)
          background ${theme.colors.content}

        .link
          font 700 16px/20px ${theme.fonts.display}

        .${styles.container}
          background ${theme.colors.content}

          :global(.desktop) &
            max-width ${theme.layout.maxWidth}
            padding-left ${indents.left}px
            padding-right ${indents.right}px
      `}</style>
      <div className={styles.container}>
        <Desktop>
          <Section>
            <Block width={5}>
              <CardVerticalL content={content[0]} />
            </Block>
            <Block width='40px' />
            <Block>
              <Feed
                card={CardVerticalS}
                interitemSpacing={30}
                content={content.slice(1)}
                columns={3}
                grid
              />
              <Link to={link.url}>
                <Button type='secondaryYellow' className={styles.linkContainer}>
                  <span className='link'>
                    {link.title}
                  </span>
                </Button>
              </Link>
            </Block>
          </Section>
        </Desktop>
        <Mobile>
          <Scroller
            className={scoped.wrapClassNames(styles.scroller)}
            listClassName={scoped.wrapClassNames('scrollerList')}
          >
            {content.map(topic => (
              <div className={styles.slide} key={topic.id}>
                <CardVerticalS content={topic} />
              </div>
            ))}
          </Scroller>
        </Mobile>
      </div>
      <Divider className={styles.divider} />
      <scoped.styles />
    </FullWidth>
  );
};

FooterTop.propTypes = {
  theme: PropTypes.object,
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
};

export default withTheme(FooterTop);
