import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';

import { Indent } from 'site/components/Wrappers';

import styles from './index.styl';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function ListHeader(props) {
  const {
    children,
    isDesktop,
    level,
    bottomIndent,
  } = props;

  const vertical = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;

  const Tag = `h${level}`;

  return (
    <Indent bottom={Number.isInteger(bottomIndent) ? bottomIndent : vertical}>
      <Tag className={styles.headline}>{children}</Tag>
    </Indent>
  );
}

ListHeader.defaultProps = {
  level: 1,
};

ListHeader.propTypes = {
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** Уровень заголовка */
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  /** По умолчанию используются вертикальные отступы из constants */
  bottomIndent: PropTypes.number,
};

const ListHeaderWithHOCs = withBreakpoint(ListHeader);

ListHeaderWithHOCs.displayName = 'ListHeader';

export default ListHeaderWithHOCs;

export { ListHeader as StorybookComponent };
