import PropTypes from 'prop-types';

import withTopic from 'core/hocs/withTopic';

import withBreakpoint from 'core/hocs/withBreakpoint';
import RandomizedRender from 'core/components/RandomizedRender';

import { Indent } from 'site/components/Wrappers';
import { PartnersHorizontal, Recommender } from 'site/components/Partners';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

function Boroda(props) {
  const {
    isMobile,
    topic,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const { infinityIndex } = topic || {};

  return (
    <Indent top={vertical}>
      {!topic || (infinityIndex % 2 !== 0) && (
        <Indent bottom={vertical}>
          <RandomizedRender probabilities={[50, 50]}>
            <Recommender />
            <PartnersHorizontal />
          </RandomizedRender>
        </Indent>
      )}

      {!topic || (infinityIndex % 2 === 0) && (
        <Indent bottom={vertical}>
          <PartnersHorizontal />
        </Indent>
      )}
    </Indent>
  );
}

Boroda.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /**
   * Индекс топика в бесконечном скролле
   */
  topic: PropTypes.shape({
    infinityIndex: PropTypes.number,
  }),
};

const BorodaWithHOCs = withTopic(withBreakpoint(Boroda));

BorodaWithHOCs.displayName = 'Boroda';

export default BorodaWithHOCs;

export { Boroda as StorybookComponent };
