import PropTypes from 'prop-types';

import withThemeSwitcher from 'core/hocs/withThemeSwitcher';

import Divider from 'core/components/Divider';

import styles from './index.styl';

function Number(props) {
  const {
    block,
    parentProps: {
      theme,
    },
  } = props;

  const {
    colors: {
      number: numberColor,
      grey5,
      primary,
      layout,
      black,
      divider2,
    },
    fonts: {
      number: numberFont,
      display: displayFont,
    },
  } = theme;

  if (!block) return null;

  const {
    body,
    value,
    unit,
  } = block.attributes;

  const countCells = value.split('');

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.container}
          color ${primary}
          &:after
            background ${layout}

        .${styles.info}
          font 16px/24px ${displayFont}

        .${styles.unit}
          font 700 24px/30px ${displayFont}
          :global(.mobile) &
            font 700 20px/25px ${displayFont}

        .${styles.cell}
          color ${numberColor}
          font 400 100px/100px ${numberFont}
          background ${primary}
          border 2px ${black} solid
          &:before
          &:after
            background-color ${grey5}
          :global(.mobile) &
            font 400 72px/72px ${numberFont}
            @media (max-width: 360px)
              font 400 58px/58px ${numberFont}

        .${styles.line}
          background-color ${divider2}

        .${styles.divider}
          background-color ${primary}
      `}
      </style>
      <div className={styles.count}>
        <div className={styles.cells}>
          {countCells.map((cell, i) => (
            <div className={styles.cell} key={i + cell}>
              {cell}
              <div className={styles.line} />
            </div>
          ))}
        </div>
        <div className={styles.unit}>{unit}</div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.info}>
        {body}
      </div>
    </div>
  );
}

Number.propTypes = {
  parentProps: PropTypes.object,
  block: PropTypes.object,
};

export default withThemeSwitcher('dark')(Number);
