import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import { formatToMoscowTimeZone } from 'core/utils/dates';
import modelPropTypes, { rubricAttributes } from 'core/utils/prop-types/model';

import { dateFormat } from 'site/utils';

import Label from 'site/components/Label';

import Clock from 'site/icons/Clock.svg';

import styles from './index.styl';


function CardInfoLine(props) {
  const {
    theme,
    rubric,
    publishedAt,
    rubricAsLabel,
    rubricLabelAtRight,
    color,
    isImportant,
  } = props;

  const showRubric = !!(rubric && rubric.enabled);
  const showRubricAtLeft = showRubric && rubricAsLabel && !rubricLabelAtRight;
  const showRubricLabelAtRight = showRubric && rubricAsLabel && rubricLabelAtRight;
  const showLastCircle = showRubric && !rubricAsLabel && !rubricLabelAtRight;
  const machineTime = publishedAt && formatToMoscowTimeZone(publishedAt);
  const humanTime = publishedAt && dateFormat(publishedAt);
  const infoColor = color || theme.colors.hintLight;
  const circleColor = color || theme.colors.divider;

  if (!showRubric && !humanTime) return null;

  return (
    <div className={styles.container}>
      <style jsx>{`
        // цветом clock управляем через .container,
        // чтобы не использовать resolveScopedStyles
        .${styles.container}
          color ${infoColor}
        .${styles.circle}
          background-color ${circleColor}
        .info
          font 12px/14px ${theme.fonts.text}
          color ${infoColor}
      `}</style>
      {showRubricAtLeft &&
        <Fragment>
          <Label rubric={rubric} isImportant={isImportant} />
          <div className={styles.circle}  />
        </Fragment>
      }
      {humanTime && (
        <Fragment>
          <Clock className={styles.clock} />
          <time dateTime={machineTime} className='info'>
            {humanTime}
          </time>
        </Fragment>
      )}
      {showLastCircle && <div className={styles.circle} />}
      {showRubric && !rubricAsLabel &&
        <div className='info'>{rubric.root_title}</div>
      }
      {showRubricLabelAtRight &&
        <Label
          className={styles.rubricAtRight}
          rubric={rubric}
          isImportant={isImportant}
        />
      }
    </div>
  );
}

CardInfoLine.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /**
   * Можно передавать как модель рубрики из апи,
   * так и только атрибуты рубрики
   */
  rubric: PropTypes.oneOfType([modelPropTypes(rubricAttributes), PropTypes.object]),
  /** Дата публикации */
  publishedAt: PropTypes.string,
  /** Отображение названия рубрики в виде метки */
  rubricAsLabel: PropTypes.bool,
  /** Позиционирование метки */
  rubricLabelAtRight: PropTypes.bool,
  /** Цвет текста, которым можем переопределить указанный в теме */
  color: PropTypes.string,
  /** Метка для дополнительного акцентирования материала */
  isImportant: PropTypes.bool,
};

const CardInfoLineWithHOCs = withTheme(CardInfoLine);
CardInfoLine.displayName = 'CardInfoLine';

export default CardInfoLineWithHOCs;

export { CardInfoLine as StorybookComponent };
