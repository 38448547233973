import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';
import withTheme from 'core/hocs/withTheme';

import Feed from 'core/components/Feed';
import Divider from 'core/components/Divider';
import { Desktop, Mobile } from 'core/components/breakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import ListDivider from 'site/components/ListDivider';
import { Indent } from 'site/components/Wrappers';
import { ListingSpec } from 'site/components/Ads/mobile';

import {
  CardVertical1S,
  CardVertical1M,
} from 'site/cards/CardVertical';
import CardSmall from 'site/cards/CardSmall';

import styles from './index.styl';

const CardSmallWithDivider = withBindProps({
  card: CardSmall,
  indent: 20,
})(ListDivider);


function News({ content, isMobile, theme }) {
  if (!content || !content.length) return null;

  const firstThree = content.slice(0, 3);
  const others = content.slice(3);

  others.forEach(topicData => delete topicData.relationships.image);

  return (
    <Fragment>
      <Feed
        content={firstThree}
        card={isMobile ? CardVertical1M : CardVertical1S}
        interitemSpacing={isMobile ? 20 : 30}
        columns={3}
      />
      <Mobile>
        <Indent bottom={20} />
        <ListingSpec dividerPosition='bottom' dividerIndent={20} />
      </Mobile>
      <Desktop>
        <Indent bottom={20} top={20}>
          <style jsx>{`
            :global(.${styles.divider})
              border 1px solid ${theme.colors.primary}
          `}</style>
          <Divider className={styles.divider} />
        </Indent>
      </Desktop>
      <Feed
        content={others}
        card={isMobile ? CardSmallWithDivider : CardVertical1S}
        interitemSpacing={isMobile ? 0 : 30}
        columns={2}
        grid
      />
    </Fragment>
  );
}

News.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(News));
