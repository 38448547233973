import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import InPageAdWrapper from 'site/components/Wrappers/InPageAd';

import styles from './index.styl';


const NativeStyles = ({ theme }) => {
  return (
    <Fragment>
      <style jsx global>{`
        .${styles.nativeInPage}
          .ad_native_content-wrapper
            color ${theme.colors.primary}

          .ad_label__text
            font 12px/1.2 ${theme.fonts.text}
            color ${theme.colors.hintLight}

          .ad_native_title
            color ${theme.colors.primary}
            font 15px/1.2 ${theme.fonts.display}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function NativeInPage(Component) {
  return withTheme(props => (
    <InPageAdWrapper>
      <div className={styles.nativeInPage}>
        <NativeStyles {...props} />
        <Component {...props} />
      </div>
    </InPageAdWrapper>
  ));
}
