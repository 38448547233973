import PropTypes from 'prop-types';
import cx from 'classnames';


import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';


import Link from 'core/components/Link';
import Divider from 'core/components/Divider';

import { Indent } from 'site/components/Wrappers';
import {
  DEFAULT_YEAR_START,
  DEFAULT_YEAR_END,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import styles from './index.styl';

const LENGTH = DEFAULT_YEAR_END - DEFAULT_YEAR_START + 1;
const YEARS_LIST = Array.from({ length: LENGTH })
  .map((_, index) => `${DEFAULT_YEAR_END - index}`);


function Years(props) {
  const {
    activeYear,
    theme,
    isMobile,
  } = props;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <div className={styles.years}>
      <style jsx>{`
        .${styles.years}
          :global(.mobile) &:after
            background linear-gradient(to right, transparent, ${theme.colors.content})
      `}</style>
      <div className={styles.list}>
        {YEARS_LIST.map(year => (
          <div key={year} className={styles.yearWrapper}>
            <Link
              to={`/archive/${year}`}
              type='primary'
            >
              <h6 className={cx(styles.year, activeYear === year && styles._active)}>{year}</h6>
            </Link>
          </div>
        ))}
      </div>
      <Indent top={verticalIndent} bottom={verticalIndent}>
        <Divider />
      </Indent>
    </div>
  );
}

Years.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  activeYear: PropTypes.string,
};

export default withTheme(withBreakpoint(Years));
