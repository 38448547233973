import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';


function LogoScooterMobile(props) {
  const {
    width,
    height,
  } = props.theme.controls.scooter.logo;
  const Logo = props.theme.icons.logo;

  return (
    <Logo
      className={styles.logoScooterMobile}
      width={width}
      height={height}
    />
  );
}

LogoScooterMobile.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
};

const LogoScooterMobileWithHOCs = withTheme(LogoScooterMobile);

LogoScooterMobileWithHOCs.displayName = 'LogoScooterMobile';

export default LogoScooterMobileWithHOCs;

export { LogoScooterMobile as StorybookComponent };
