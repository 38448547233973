import PropTypes from 'prop-types';

import Scroller from 'core/components/Scroller';
import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import withTheme from 'core/hocs/withTheme';
import withThemeSwitcher from 'core/hocs/withThemeSwitcher';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import { CardMainFadedHero1 } from 'site/cards/CardMainFadedHero';

import CardUnderlined from './CardUnderlined';
import styles from './mobile.styl';

const relationships = resolveRelationships(['image'], {}, {
  image: {
    versions: {},
  },
});


function FadedMobile(props) {
  const {
    content,
    theme,
  } = props;

  const [heroTopic, ...otherTopics] = content;

  if (!heroTopic) return null;

  const { indents } = theme.layout;
  const { link } = heroTopic.attributes;
  const {
    image: {
      versions: {
        original: originalCover,
      },
    },
  } = relationships(heroTopic);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.scroller}
           margin-left 0

        .scrollerList
          padding-right ${indents.right}px
      `}</style>
    </scope>
  );

  return (
    <div className='fadedMobile'>
      <style jsx>{`
        .fadedMobile
          background ${theme.colors.content}
        .${styles.hero}
          background-color ${theme.colors.text}
        :global(.${styles.imageLink}):after
          background linear-gradient(0deg, ${theme.colors.content}, transparent 50%)
      `}</style>
      <div className={styles.imageArea}>
        <Link to={link} className={styles.imageLink}>
          <SmartImage
            src={originalCover}
            maxWidth={750}
            aspectRatio={1}
          />
        </Link>
        <div className={styles.heroCard}>
          <CardMainFadedHero1 content={heroTopic} />
        </div>
      </div>
      <Scroller
        className={scoped.wrapClassNames(styles.scroller)}
        listClassName={scoped.wrapClassNames('scrollerList')}
      >
        {otherTopics.map(topic => (
          <div key={topic.id} className={styles.slide}>
            <CardUnderlined
              key={topic.id}
              content={topic}
              className={styles.card}
            />
          </div>
        ))}
      </Scroller>
      <scoped.styles />
    </div>
  );
}

FadedMobile.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  theme: PropTypes.object,
};

export default withThemeSwitcher('dark')(withTheme(FadedMobile));
