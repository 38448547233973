import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { rubricAttributes } from 'core/utils/prop-types/model';
import withTheme from 'core/hocs/withTheme';

import Lightning from 'site/icons/Lightning.svg';

import styles from './index.styl';


const EMPTY = {};

function Label(props) {
  const {
    className,
    text,
    theme,
    rubric,
    isImportant,
  } = props;

  if (!(rubric || text)) return null;

  const {
    root_title: labelText = text,
  } = rubric || (rubric && rubric.attributes) || EMPTY;

  return (
    <div
      className={cx(
        className,
        styles.label,
        isImportant && styles._important,
      )}
    >
      <style jsx>{`
        .${styles.label}
          background ${theme.colors.yellow}
          color ${theme.colors.text}
          font 600 8px/10px ${theme.fonts.display}
      `}</style>
      {isImportant && <Lightning className={styles.lightning} />}
      {labelText}
    </div>
  );
}

Label.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** Текст */
  text: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
  /** Можно передавать как модель рубрики из апи,
   * так и только атрибуты рубрики */
  rubric: PropTypes.oneOfType([
    modelPropTypes(rubricAttributes),
    rubricAttributes,
  ]),
  isImportant: PropTypes.bool,
};

const LabelWithHOCs = withTheme(Label);

LabelWithHOCs.displayName = 'Label';

export default LabelWithHOCs;

export { Label as StorybookComponent };
