import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import footerQuery from 'site/queries/footer';
import { bebop as bebopApi } from 'core/api/definitions/bebop';
import { processQueryResults } from 'core/utils/query';

import { denormalizeData } from 'core/utils/api';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/hocs/withBreakpoint';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';
import StickyPortal from 'core/components/StickyPortal';
import ColumnLayout from 'core/components/ColumnLayout';
import PageLoader from 'core/components/Loader/PageLoader';

import {
  Ad240x400,
  Ad240x400Second,
  SuperFooter,
  Parallax,
  Native1AsCardVertical,
  Native2AsCardVertical,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
} from 'site/components/Ads/mobile';

import FancyBlock from 'site/components/FancyBlock';
import { Recommender } from 'site/components/Partners';
import { PageBoroda } from 'site/components/Wrappers';
import {
  PageIndent,
  Indent,
  NegativeMobile,
} from 'core/components/Wrappers';
import SelfPromo from 'core/components/SelfPromo';

import DarkTop from './DarkTop';
import OnePlusThree from './OnePlusThree';
import News from './News';
import UserStories from './UserStories';
import Faded from './Faded';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';


const TOPIC_FIELDS = 'headline,list_headline,alternative_headline,published_at,link,topic_type,ad_label,link_with_announce_erid,advertiser_name';
const LISTS_CONFIG = {
  main: {
    limit: 9,
    include: 'rubric,tags',
    fields: 'headline,list_headline,published_at,link,topic_type,ad_label',
  },
  themeoftheday1: {
    limit: 6,
    include: 'rubric,image',
    fields: TOPIC_FIELDS,
  },
  themeoftheday2: {
    limit: 4,
    include: 'rubric,image',
    fields: 'headline,published_at,link',
  },
  ugc: {
    limit: 7,
    autocompleted_list_limit: 7,
    include: 'rubric,image',
    fields: TOPIC_FIELDS,
    visibility: 'main_page',
  },
};


function MainPage({ isMobile, history }) {
  const listNames = Object.keys(LISTS_CONFIG);

  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  const {
    data: [
      main,
      themeoftheday1,
      themeoftheday2,
      ugc,
      themeOfTheDay1Data,
    ],
    isLoading,
  } = processQueryResults(useRequest({
    queries: [
      ...listNames.map(listName => topicsQuery({
        list: listName,
        sort: 'list',
        history,
        ...LISTS_CONFIG[listName],
      })),
      {
        queryKey: ['bebopApi', 'getList', { list_slug: 'themeoftheday1' }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
        select: denormalizeData,
      },
    ],
  }));

  const mainLists = [main, themeoftheday1, themeoftheday2, ugc].filter(Boolean);
  const excludeIds = mainLists && mainLists.map(list => list.map(({ id }) => id)).flat();

  const { data: news, isLoading: newsIsLoading } = useRequest({
    ...topicsQuery({
      topic_type: 'news',
      visibility: 'main_page',
      sort: '-published_at',
      limit: 7,
      include: 'image,rubric',
      fields: TOPIC_FIELDS,
      excluded_ids: excludeIds,
      history,
    }),
    enabled: excludeIds.length > 0,
  });

  const { data: articles, isLoading: articlesIsLoading } = useRequest({
    ...topicsQuery({
      topic_type: 'article,gallery',
      visibility: 'main_page',
      sort: '-published_at',
      limit: 4,
      include: 'image,rubric',
      fields: TOPIC_FIELDS,
      excluded_ids: excludeIds,
      history,
    }),
    enabled: excludeIds.length > 0,
  });

  const {
    title: theme1Title,
    subtitle: theme1Subtitle,
  } = themeOfTheDay1Data?.attributes || {};

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const FancyStyler = isMobile ? EmptyWrapper : FancyBlock;

  if (isLoading || newsIsLoading || articlesIsLoading) return <PageLoader />;

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <Page>
        <PageIndent>
          <NegativeMobile>
            <DarkTop content={main} />
          </NegativeMobile>
          <Indent bottom={vertical} />
          <ColumnLayout
            rightColumn={(
              <StickyPortal listenStickyRemoveEvent>
                <AdWrapper bottom={vertical}>
                  <Ad240x400 />
                  <SelfPromo />
                  <Indent bottom={vertical} />
                  <Ad240x400Second />
                </AdWrapper>
              </StickyPortal>
            )}
          >
            <AdWrapper bottom={vertical}>
              <Mobile>
                <Listing1 />
              </Mobile>
              <FancyStyler>
                <OnePlusThree
                  title={theme1Title || 'Картина дня'}
                  {...!isMobile && {
                    subtitle: theme1Subtitle,
                  }}
                  content={themeoftheday1}
                  native={Native1AsCardVertical}
                />
              </FancyStyler>
              <Indent bottom={vertical} />
              <Desktop>
                <SuperFooter />
              </Desktop>
              <News content={news} />
              <Indent bottom={isMobile ? vertical : 40} />
              <Mobile>
                <Listing2 />
              </Mobile>
              <NegativeMobile>
                <FancyStyler top='-30px'>
                  <Faded content={themeoftheday2} />
                </FancyStyler>
              </NegativeMobile>
              <Indent bottom={vertical} />
              <OnePlusThree
                content={articles}
                native={Native2AsCardVertical}
                anotherHero
              />
              <Indent bottom={vertical} />
            </AdWrapper>
          </ColumnLayout>
          <Desktop>
            <AdWrapper bottom={vertical}>
              <Parallax />
            </AdWrapper>
          </Desktop>
          <ColumnLayout rightColumn={<Fragment />}>
            <UserStories content={ugc} />
          </ColumnLayout>
          <Recommender />
          <Indent bottom={vertical} />
        </PageIndent>
      </Page>
    </PageBoroda>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(MainPage);
