import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import footerQuery from 'site/queries/footer';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import Page from 'core/components/Page';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import resolveRelationships from 'core/utils/relationships';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import InnerSideColumn from 'site/components/InnerSideColumn';
import {
  Indent,
  PageBoroda,
} from 'site/components/Wrappers';

import TopicHeader from 'site/pages/topic/TopicHeader';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard({ isMobile, theme, ...routerParams }) {
  const [
    { data: content, isLoading: contentAreLoading },
    { data: footerContent, isLoading: footerContentIsLoading },
  ] = useRequest({
    queries: [
      topicQuery(routerParams),
      footerQuery(routerParams),
    ],
  });

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <Page>
        <PageIndent>
          <style jsx>{`
            .announce
              font 18px/27px ${theme.fonts.text}
              color ${theme.colors.primary}
              :global(.mobile) &
                font 16px/22px ${theme.fonts.text}
          `}</style>
          {contentAreLoading && <PageLoader />}
          {!contentAreLoading &&
            <Topic content={content}>
              {topic => {
                const {
                  content: {
                    widgets,
                  },
                } = relationships(topic);

                if (widgets.length === 0) return null;

                const {
                  final_announce: finalAnnounce,
                } = widgets[0].attributes;

                return (
                  <ColumnLayout rightColumn={<InnerSideColumn />}>
                    <TopicHeader content={topic} isGameCompare />
                    <Indent top={20} bottom={isMobile ? 35 : 25} >
                      <div className='announce'>{finalAnnounce}</div>
                    </Indent>
                    <LeaderBoardCore content={topic} />
                  </ColumnLayout>
                );
              }}
            </Topic>
          }
        </PageIndent>
      </Page>
    </PageBoroda>
  );
}

LeaderBoard.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(LeaderBoard));
