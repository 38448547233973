import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';


function InPageAdWrapper({ children, theme }) {
  return (
    <div className={styles.inPageAdWrapper}>
      <style jsx>{`
        .${styles.inPageAdWrapper} :global([data-render-state='rendered'])
          &:before
            background ${theme.colors.grey2}
          & > :global(div)
            background ${theme.colors.content}
          &:after
            font 14px/17px ${theme.fonts.text}
            color ${theme.colors.hint}
      `}</style>
      {children}
    </div>
  );
}

InPageAdWrapper.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(InPageAdWrapper);
