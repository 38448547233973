import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';


function FancyBlock({ children, top, left, theme }) {
  return (
    <div className={styles.fancy}>
      <style jsx>{`
        .${styles.fancy}:before
          top ${top}
          left ${left}
          background ${theme.colors.yellow}
      `}</style>
      <div className={styles.children}>
        {children}
      </div>
    </div>
  );
}

FancyBlock.propTypes = {
  /** Вертикальное смещение */
  top: PropTypes.string,
  /** Горизонтальное смещение */
  left: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
};

FancyBlock.defaultProps = {
  top: '-11px',
  left: '-40px',
};

const FancyBlockWithHOCs = withTheme(FancyBlock);

FancyBlockWithHOCs.displayName = 'FancyBlock';

export default FancyBlockWithHOCs;

export { FancyBlock as StorybookComponent };
