import { nest, compose } from 'core/libs/recompose';

import withBindProps from 'core/hocs/withBindProps';

import Ad from 'core/components/Ad';

import { NegativeMobile } from 'site/components/Wrappers';
import inPageStyles from './styles/inPage';
import vertical from './styles/vertical';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const commonProps = {
  siteId: '459973582',
  height: 250,
  hideOnPreview: true,
};

const listingSpecProps = withBindProps({
  ...commonProps,
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '459973954',
  hideOnPreview: true,
  options: {
    p1: 'bxuur',
    p2: 'fqbd',
    pct: 'a',
  },
});

export const TopBanner = withBindProps({
  ...commonProps,
  name: 'TopBanner',
  blockId: '459973718',
  hideOnPreview: true,
  options: {
    p1: 'bswnc',
    p2: 'fexc',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = withBindProps({
  ...commonProps,
  name: 'Fullscreen',
  height: null,
  blockId: '459974426',
  hideOnPreview: true,
  options: {
    p1: 'bswnb',
    p2: 'etsa',
    pct: 'a',
  },
})(Ad);

export const Rich = withBindProps({
  ...commonProps,
  name: 'Rich',
  height: null,
  blockId: '459973796',
  hideOnPreview: true,
  options: {
    p1: 'btgyw',
    p2: 'ffhi',
    pct: 'a',
  },
})(Ad);

export const Listing1 = withBindProps({
  ...commonProps,
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '459974324',
  hideOnPreview: true,
  options: {
    p1: 'bwzfo',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Listing2 = withBindProps({
  ...commonProps,
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '459974322',
  hideOnPreview: true,
  options: {
    p1: 'bwzfk',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Listing3 = withBindProps({
  ...commonProps,
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '459974320',
  hideOnPreview: true,
  options: {
    p1: 'bwzfn',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Listing4 = withBindProps({
  ...commonProps,
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '459974124',
  hideOnPreview: true,
  options: {
    p1: 'bwzfm',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const ListingSpec = compose(
  listingSpecProps,
  vertical,
)(Ad);

export const Footer = withBindProps({
  ...commonProps,
  name: 'Footer (COM)',
  lazy: true,
  blockId: '459974432',
  hideOnPreview: true,
  options: {
    p1: 'bwzfq',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content1 = withBindProps({
  ...commonProps,
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '459974540',
  hideOnPreview: true,
  options: {
    p1: 'bwzfr',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Content2 = withBindProps({
  ...commonProps,
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '459974538',
  hideOnPreview: true,
  options: {
    p1: 'bwzfp',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Content3 = withBindProps({
  ...commonProps,
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '459974534',
  hideOnPreview: true,
  options: {
    p1: 'bwzfl',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Content4 = withBindProps({
  ...commonProps,
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '459974532',
  hideOnPreview: true,
  options: {
    p1: 'bwzft',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const ContentSpec = compose(
  withBindProps({
    ...commonProps,
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '459974526',
    hideOnPreview: true,
    options: {
      p1: 'bxuus',
      p2: 'fqbd',
      pct: 'a',
    },
  }),
  inPageStyles,
)(Ad);

export const InPage = withBindProps({
  ...commonProps,
  name: 'InPage',
  height: 200,
  blockId: '459974416',
  hideOnPreview: true,
  options: {
    p1: 'bswnd',
    p2: 'fexd',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Sponsored = compose(
  withBindProps({
    ...commonProps,
    name: 'Sponsored (COM)',
    siteId: '459973582',
    blockId: '459973738',
    height: null,
    hideOnPreview: true,
    options: {
      p1: 'bwzfs',
      p2: 'fooq',
      pct: 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const InGallery = withBindProps({
  ...commonProps,
  name: 'InGallery',
  blockId: '579389982',
  disableAutoInit: true,
  height: null,
  hideOnPreview: true,
})(Ad);
