import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import EmptyWrapper from 'core/components/EmptyWrapper';
import StoryTopics from 'core/components/stories/StoryTopics';
import { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Mobile, Desktop } from 'core/components/breakpoint';
import withThemeSwitcher from 'core/hocs/withThemeSwitcher';
import { Indent } from 'core/components/Wrappers';
import AdWrapper from 'core/components/Ad/AdWrapper';
import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import { GlobalTopicContext } from 'core/components/GlobalTopicProvider';

import CookiesBanner from 'core/components/CookiesBanner';

import { isError4xx } from 'core/utils/app-status-helper';

import Themer from 'site/components/Themer';
import Scooter from 'site/components/Scooter';
import DarkWrapper from 'site/components/DarkWrapper';
import Shapka from 'site/components/Shapka';

import {
  Billboard,
  Background,
  Fullscreen as DesktopFullscreen,
} from 'site/components/Ads/desktop';
import {
  TopBanner,
  Rich,
  Fullscreen as MobileFullscreen,
} from 'site/components/Ads/mobile';

import { isEnciklopediya } from 'site/utils';

import {
  SIDE_INDENT,
  LIFT_PATHNAME,
  ENCIKLOPEDIYA_PATHNAME,
} from 'site/constants';

import styles from './index.styl';


const DarkWrapperComponent = withThemeSwitcher('enciklopediya')(DarkWrapper);


function isError({ location, staticContext }) {
  return isError4xx((location?.state || staticContext || {}).appStatus);
}

function getThemeName({ location, staticContext }) {
  const isEnciklopediyaPath = isEnciklopediya(location.pathname);
  const isErrorPage = isError({ location, staticContext });

  return isErrorPage || isEnciklopediyaPath
    ? 'enciklopediya'
    : 'light';
}

function SiteLayout(props) {
  const {
    children,
    isMobile,
    theme,
    staticContext,
    location,
  } = props;

  const topic = useContext(GlobalTopicContext);
  const [themeName, updateThemeName] = useState(getThemeName({ location, staticContext }));

  useEffect(() => {
    const name = getThemeName({ location, staticContext });

    if (name === themeName || location?.state?.infinity) return;

    updateThemeName(name);
  }, [location, staticContext, themeName]);

  const {
    is_premium: isPremiumTopic,
  } = topic?.attributes || {};

  const { indents } = theme.layout;

  const isErrorPage = isError(props);
  const mainPageEnciklopediya = location.pathname === ENCIKLOPEDIYA_PATHNAME;
  const disableAds = location.pathname === LIFT_PATHNAME || isErrorPage || mainPageEnciklopediya;

  const Wrapper = themeName === 'enciklopediya' ? DarkWrapperComponent : EmptyWrapper;
  const ShapkaWrapper = isMobile ? EmptyWrapper : Indent;

  return (
    <StickyContainer>
      <style jsx>{`
        .${styles.stories}
          :global(.desktop) &
            margin-left ${indents.left}px

        .${styles.storiesContent}
          padding-right ${indents.left * 2}px

          :global(.mobile) &
            padding-left ${indents.left}px
            padding-right ${indents.left}px
      `}</style>
      <Wrapper>
        <Layout>
          <Themer themeName={themeName}>
            <Header>
              <ShapkaWrapper left={SIDE_INDENT} right={SIDE_INDENT}>
                <Shapka isSecondShapkaForPremium={isPremiumTopic} fullwidth />
              </ShapkaWrapper>
            </Header>
            <Content>
              {(!disableAds && !isPremiumTopic) && (
                <AdWrapper bottom={20}>
                  <Desktop>
                    <Indent left={SIDE_INDENT} right={SIDE_INDENT}>
                      <Billboard />
                    </Indent>
                  </Desktop>
                  <Mobile>
                    <TopBanner />
                  </Mobile>
                </AdWrapper>
              )}
              {!isPremiumTopic && (
                <div className={styles.stories}>
                  <div className={styles.storiesContent}>
                    <StoryTopics />
                  </div>
                </div>
              )}
              {children}
            </Content>
            <Footer>
              <Themer themeName={themeName === 'enciklopediya' ? 'enciklopediya' : 'dark'}>
                <Scooter />
              </Themer>
            </Footer>
            <CookiesBanner />
          </Themer>
        </Layout>
      </Wrapper>
      {!disableAds && (
        <>
          <Desktop>
            <Background />
            <DesktopFullscreen />
          </Desktop>
          <Mobile>
            <Rich />
            <MobileFullscreen />
          </Mobile>
        </>
      )}
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  staticContext: PropTypes.object,
};

export default withBreakpoint(withTheme(withRouter(SiteLayout)));
