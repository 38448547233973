import PropTypes from 'prop-types';

import Scooter from 'core/components/Scooter';
import withTheme from 'core/hocs/withTheme';
import Link from 'core/components/Link';
import FullWidth from 'core/components/FullWidth';

import styles from './index.styl';


const SiteScooter = ({ theme }) => {
  return (
    <FullWidth className={styles.fullwidth} ignoreLayoutIndents>
      <style jsx>{`
        :global(.${styles.fullwidth})
          background ${theme.colors.content}
        .${styles.innerContainer}
          max-width ${theme.layout.maxWidth}
      `}</style>
      <div className={styles.innerContainer}>
        <Scooter
          services={[
            { link: '/about', content: 'Редакция' },
            { link: '/special', content: 'Спецпроекты' },
            { link: '/about#advertising', content: 'Реклама' },
            { link: '/exports/rss.xml', content: 'RSS' },
            { link: '/legal', content: 'Правовая информация' },
            { link: '/legal/terms-of-use', content: 'Условия использования' },
            { link: '/legal/privacy', content: 'Политика конфиденциальности' },
          ]}
          commercial={[
            'Все права защищены. Полное или частичное копирование материалов в коммерческих целях возможно только с письменного разрешения владельца сайта. В случае обнаружения нарушений виновные могут быть привлечены к ответственности в соответствии с законодательством Российской Федерации.',
            {
              body: <Link type='tertiary' to='/rules-recommenders'>На информационном ресурсе применяются рекомендательные технологии в соответствии с Правилами</Link>,
            },
          ]}
          contacts={{
            site: '«Секрет фирмы»',
            age: 18,
            phone: {
              caption: 'Телефон редакции:',
              body: '+7 495 785-17-00',
            },
          }}
        />
      </div>
    </FullWidth>
  );
};

SiteScooter.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(SiteScooter);
