import PropTypes from 'prop-types';

import withThemeSwitcher from 'core/hocs/withThemeSwitcher';
import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { Desktop } from 'core/components/breakpoint';

import SocialShare from 'site/components/SocialShare';
import SmartImage from 'core/components/SmartImage';

import img from './crossword.png';

import styles from './index.styl';


function EnciklopediyaDescription({ theme, isMobile }) {
  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.container}
          &:after
            background-image url(${img})

        .${styles.heading}
          font 700 40px/44px ${theme.fonts.text}
          color ${theme.colors.yellow}
          :global(.mobile) &
            font 700 21px/24px ${theme.fonts.text}
        .${styles.text}
          font 400 24px/32px ${theme.fonts.text}
          color ${theme.colors.yellow}
          :global(.mobile) &
            font 16px/22px ${theme.fonts.text}

        .${styles.shareText}
          font 400 16px/24px ${theme.fonts.text}
          color ${theme.colors.yellow}
      `}</style>
      <div className={styles.content}>
        <div className={styles.info}>
          <h1 className={styles.heading}>Энциклопедия «Секрета&nbsp;фирмы»</h1>
          <div className={styles.text}>
            Объясняем простыми словами
          </div>
        </div>
        <div className={styles.image}>
          <SmartImage
            url={img}
            width={isMobile ? 280 : 383}
            height={isMobile ? 292 : 400}
          />
        </div>
      </div>
      <Desktop>
        <div className={styles.socialShare}>
          <div className={styles.shareText}>Поделиться:</div>
          <SocialShare />
        </div>
      </Desktop>
    </div>
  );
}

EnciklopediyaDescription.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const EnciklopediyaDescriptionWithHOCs = withThemeSwitcher('dark')(withTheme(withBreakpoint(EnciklopediyaDescription)));

EnciklopediyaDescriptionWithHOCs.displayName = 'EnciklopediyaDescription';

export default EnciklopediyaDescriptionWithHOCs;

export { EnciklopediyaDescription as StorybookComponent };
