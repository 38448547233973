import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import footerQuery from 'site/queries/footer';
import { filterRequiredParams } from 'core/utils/api';

import withBreakpoint from 'core/hocs/withBreakpoint';

import ColumnLayout from 'core/components/ColumnLayout';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import PageLoader from 'core/components/Loader/PageLoader';
import {
  Indent,
  PageIndent,
} from 'core/components/Wrappers';

import ListHeader from 'site/components/ListHeader';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { PageBoroda } from 'site/components/Wrappers';

import { CardVerticalL } from 'site/cards/CardVertical';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

const PAGE_TITLE = 'Спецпроекты';

function Special({ isMobile, history }) {
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  const { data: topics, isLoading: topicsAreLoading } = useRequest(topicsQuery({
    topic_type: 'specproject',
    fields: filterRequiredParams([CardVerticalL], 'fields'),
    include: 'image',
    history,
  }));

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <Page title={PAGE_TITLE}>
        <PageIndent>
          <ColumnLayout rightColumn={<InnerSideColumn />}>
            <ListHeader>{PAGE_TITLE}</ListHeader>
            <Indent top={vertical} />
            {topicsAreLoading && <PageLoader />}
            {!topicsAreLoading &&
              <Feed
                grid
                content={topics}
                card={CardVerticalL}
                columns={2}
                interitemSpacing={50}
              />
            }
          </ColumnLayout>
          <Boroda />
        </PageIndent>
      </Page>
    </PageBoroda>
  );
}

Special.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(Special);
