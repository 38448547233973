import { Route } from 'core/libs/router';
import PropTypes from 'prop-types';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import withBindProps from 'core/hocs/withBindProps';

import Layout from 'site/components/Layout';

import { LIFT_PATHNAME, ENCIKLOPEDIYA_PATHNAME } from 'site/constants';

import Comments from 'site/pages/comments';
import Main from 'site/pages/main';
import Topic from 'site/pages/topic';
import RubricPage from 'site/pages/rubric';
import TagsPage from 'site/pages/tag';
import Author from 'site/pages/author';
import Search from 'site/pages/search';
import Archive from 'site/pages/archive';
import Authors from 'site/pages/authors';
import NewsPage from 'site/pages/news';
import LiftPage from 'site/pages/lift';
import Special from 'site/pages/special';
import Enciklopediya from 'site/pages/enciklopediya';
import LeaderBoard from 'site/pages/leaderBoard';
import About from 'site/pages/about';
import LegalPage from 'site/pages/legal';
import RulesRecommenders from 'site/pages/rulesRecommenders';
import Profile from 'site/pages/profile';

import configResolver from './shared-app-config';

import styles from './index.styl';


function Site(props, context) {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver(context.requestHeaders);

  return (
    <div className={styles.horScrollFixer}>
      <App {...config}>
        <Layout>
          <BaseRoutes
            main={Main}
            rubrics={RubricPage}
            tags={TagsPage}
            topic={Topic}
            about={About}
            search={Search}
            comments={Comments}
            author={Author}
            authors={Authors}
            profile={Profile}
            legal={LegalPage}
            leaderBoard={LeaderBoard}
            rulesRecommenders={withBindProps({ mail: 'info@secretmag.ru' })(RulesRecommenders)}
          >
            <Route
              exact
              path='/news'
              component={NewsPage}
            />
            <Route
              exact
              path={LIFT_PATHNAME}
              component={LiftPage}
            />
            <Route
              path='/archive/:year?'
              component={Archive}
            />
            <Route
              exact
              path='/special'
              component={Special}
            />
            <Route
              exact
              path={ENCIKLOPEDIYA_PATHNAME}
              component={Enciklopediya}
            />
          </BaseRoutes>
        </Layout>
      </App>
    </div>
  );
}

Site.contextTypes = {
  requestHeaders: PropTypes.shape({
    host: PropTypes.string,
  }),
};

export default Site;
