import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';


function CardTopicBg({ children, theme }) {
  return (
    <div className={styles.cardTopicBg}>
      <style jsx>{`
        .${styles.cardTopicBg}
          background-color ${theme.colors.grey2}
      `}</style>
      {children}
    </div>
  );
}

CardTopicBg.propTypes = {
  children: PropTypes.element,
  theme: PropTypes.object,
};

export default withTheme(CardTopicBg);
