import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import CardSmall  from 'site/cards/CardSmall';

import styles from './index.styl';


function WrappedCard(props) {
  const {
    theme,
    children,
  } = props;

  const bcColor = theme.colors.layout === '#fff' ? theme.colors.content : theme.colors.grey;

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.wrapper}
          background-color ${bcColor}
      `}</style>
      {children || <CardSmall {...props} />}
    </div>
  );
}

WrappedCard.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(WrappedCard);
