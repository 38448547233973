import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import { dateFormat } from 'core/utils/dates';

import H4 from 'core/components/H4';
import Link from 'core/components/Link';
import Image from 'core/components/Image';

import { bytesToSize } from 'site/utils';
import { LAYOUT_MAX_WIDTH_MOBILE } from 'site/constants';

import styles from './index.styl';

const COVER_SIZES = {
  width: 180,
  height: 240,
};

function CardArchive({ content, isMobile, theme }) {
  const {
    imageUrl,
    title,
    url,
    date: publishedAt,
    size,
  } = content;

  const newSize = bytesToSize(size);

  const date = dateFormat(publishedAt, 'dd.MM.yyyy');

  const cover = {
    rel_url: imageUrl,
    ...COVER_SIZES,
  };

  return (
    <Link
      type='primary'
      to={url}
      target='_blank'
    >
      <style jsx>{`
        .${styles.date}
          color ${theme.colors.hint}
          font 16px/1.2 ${theme.fonts.nuance}

        .${styles.fileInfo}
          font 16px/1.2 ${theme.fonts.nuance}
      `}</style>
      <div className={styles.innerWrapper}>
        <div className={styles.image}>
          <Image
            maxWidth={isMobile ? LAYOUT_MAX_WIDTH_MOBILE : COVER_SIZES.width}
            src={cover}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <H4>{title}</H4>
          </div>
          <div className={styles.date}>{date}</div>
          <div className={styles.fileInfo}>PDF ({newSize})</div>
        </div>
      </div>
    </Link>
  );
}

CardArchive.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,

  /** @ignore */
  theme: PropTypes.object,
};

const CardArchiveWithHOCs = withTheme(withBreakpoint(CardArchive));
CardArchiveWithHOCs.displayName = 'CardArchive';

export default CardArchiveWithHOCs;

export { CardArchive as StorybookComponent };

