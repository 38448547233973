import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';

import { CardVerticalS } from 'site/cards/CardVertical';

import styles from './cardUnderlined.styl';


function CardUnderlined({ theme, className, ...otherProps }) {
  return (
    <div className={cx(styles.underlined, className)}>
      <style jsx>{`
        .${styles.underlined}:after
          background ${theme.colors.yellow}
      `}</style>
      <CardVerticalS {...otherProps} />
    </div>
  );
}

CardUnderlined.propTypes = {
  theme: PropTypes.object,
  className: PropTypes.string,
};

export default withTheme(CardUnderlined);
