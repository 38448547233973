import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawContentQuery } from 'core/queries/searchPage';

import withBindProps from 'core/hocs/withBindProps';
import withBreakpoint from 'core/hocs/withBreakpoint';

import CoreSearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { PageIndent } from 'core/components/Wrappers';

import { PageBoroda } from 'site/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import CardHorizontal from 'site/cards/CardHorizontal';
import { CardVertical1S } from 'site/cards/CardVertical';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';

import footerQuery from 'site/queries/footer';


const mobileIndent = 10;
const ListingSpecDivided = withBindProps({
  dividerPosition: 'both',
  dividerIndent: mobileIndent,
  addDividerMargins: true,
})(ListingSpec);


/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function SearchPage({ isMobile, location, history }) {
  const Card = isMobile ? CardVertical1S : CardHorizontal;
  const { data: rawContent, isLoading } = useRequest(rawContentQuery({ card: Card, location, history }));
  const { data: footerContent, isLoading: footerContentIsLoading } = useRequest(footerQuery({ history }));

  return (
    <PageBoroda content={footerContent} isLoading={footerContentIsLoading}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
          {/*
            В SearchPage есть собственный AdWrapper.
            Но из-за комопнента PageBoroda ему не хватает веса специфичности.
            Еще почему-то не перезаписывается значение 20, нужно 21.
          */}
          <AdWrapper top={0} bottom={21}>
            <CoreSearchPage
              isLoading={isLoading}
              rawContent={rawContent}
              ad1={Context}
              ad2={SuperFooter}
              ad1Mobile={Listing1}
              ad2Mobile={ListingSpecDivided}
              card={Card}
              interitemSpacing={20}
            />
          </AdWrapper>
        </ColumnLayout>
      </PageIndent>
    </PageBoroda>
  );
}

SearchPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withBreakpoint(SearchPage);
