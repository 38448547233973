import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Mjolnir from 'core/components/Mjolnir';
import Feed from 'core/components/Feed';
import { Indent } from 'site/components/Wrappers';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import ListHeader from 'site/components/ListHeader';

import {
  CardHorizontal1L,
  CardHorizontal2L,
} from 'site/cards/CardHorizontal';
import {
  CardVertical1M,
  CardVertical1S,
  CardVertical2L,
} from 'site/cards/CardVertical';

import styles from './index.styl';


function OnePlusThree(props) {
  const {
    content,
    title,
    subtitle,
    anotherHero,
    native: Native,
    isMobile,
    isDesktop,
    theme,
  } = props;

  if (!content || !content.length) return null;

  const first = content.slice(0, 4);
  const second = content.slice(4);
  let Card;
  if (second.length === 1) {
    Card = isMobile ?  CardVertical1M : CardHorizontal1L;
  } else {
    Card = CardVertical1S;
  }
  const Presenter = isMobile ? Feed : Mjolnir;
  const TitleElement = isMobile ? 'div' : 'span';

  return (
    <EmptyWrapper>
      <style jsx>{`
        .${styles.subtitle}
          color ${theme.colors.divider}
      `}</style>
      <ListHeader level={2} bottomIndent={20}>
        <TitleElement className={styles.title}>{title}</TitleElement>
        {!!subtitle && <TitleElement className={styles.subtitle}>{' ' + subtitle}</TitleElement>}
      </ListHeader>
      <Presenter
        content={first}
        {...isDesktop && {
          heroCard: anotherHero ? CardHorizontal2L : CardHorizontal1L,
          card: CardVertical1S,
          interitemSpacing: 30,
        }}
        {...isMobile && {
          heroCard: CardVertical2L,
          card: CardVertical1M,
          interitemSpacing: 20,
        }}
        {...!!Native && {
          cardsReplacement: {
            2: withReplaceCardWithAd(Native, !isMobile),
          },
        }}
      />
      {second &&
        <Indent top={20}>
          <Feed
            content={second}
            card={Card}
            interitemSpacing={isMobile ? 20 : 30}
            columns={second.length}
          />
        </Indent>
      }
    </EmptyWrapper>
  );
}

OnePlusThree.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  native: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  anotherHero: PropTypes.bool,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(OnePlusThree));
