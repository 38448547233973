import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/hocs/withTheme';
import Link from 'core/components/Link';

import styles from './index.styl';


function CardEnciklopedy(props) {
  const {
    theme,
    content,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    announce,
  } = content.attributes;

  return (
    <Link to={link}  type='secondary'>
      <style jsx>{`
        .${styles.container}
          color ${theme.colors.primary}
          border 1px solid ${theme.colors.grey4}
          background-color ${theme.colors.layout}

        .${styles.left}
          border-color transparent ${theme.colors.primary} transparent transparent
          &:before
            border-color transparent ${theme.colors.layout} transparent transparent

        .${styles.right}
          border-color transparent transparent transparent ${theme.colors.primary}
          &:after
            border-color transparent transparent transparent ${theme.colors.layout}

        .${styles.headline}
          background ${theme.colors.layout}
          border-top 1px solid ${theme.colors.primary}
          border-bottom 1px solid  ${theme.colors.primary}
          font bold 24px/40px ${theme.fonts.display}
          :global(.mobile) &
            font bold 18px/40px ${theme.fonts.display}

        .announce
          font bold 16px/23px ${theme.fonts.text}
        `}
      </style>
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <div className={styles.left} />
          <div className={styles.headline}>{listHeadline || headline}</div>
          <div className={styles.right} />
        </div>
        <div className='announce'>{announce}</div>
      </div>
    </Link>
  );
}

CardEnciklopedy.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
};

const CardEnciklopedyWithHOCs = withTheme(CardEnciklopedy);

CardEnciklopedyWithHOCs.displayName = 'CardEnciklopedy';

export default CardEnciklopedyWithHOCs;

export { CardEnciklopedy as StorybookComponent };
