import PropTypes from 'prop-types';

import EmptyWrapper from 'core/components/EmptyWrapper';
import TopicContent from 'core/components/TopicContent';
import resolveRelationships from 'core/utils/relationships';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';

import { Indent } from 'site/components/Wrappers';

import { getLastMergedMarkdownId } from 'site/utils';

import styles from './topicContent.styl';

import {
  InRead,
  InPage,
  AdCenter,
} from 'site/components/Ads/desktop';

import {
  InPage as InPageMobile,
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpec,
} from 'site/components/Ads/mobile';

import * as siteWidgets from 'site/widgets'; // eslint-disable-line import/no-namespace

const blocks = Object.keys(siteWidgets).reduce((result, widgetName) => {
  const blockName = widgetName[0].toLowerCase() + widgetName.slice(1);
  const Widget = siteWidgets[widgetName];

  result[blockName] = (block, parentProps, ctx) => (
    <Widget
      block={block}
      parentProps={parentProps}
      ctx={ctx}
    />
  );

  return result;
}, {});

const relationships = resolveRelationships(['content'], {});

const desktopAds = [
  {
    before: 200,
    after: 200,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 1200,
    after: 200,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 600,
    after: 200,
    renderer: InPage,
  },
  {
    before: 1200,
    after: 200,
    renderer: AdCenter,
  },
];

const mobileAds = [
  Content1,
  InPageMobile,
  ContentSpec,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 600,
  after: 200,
  showOnlyOnce: ads.length !== index + 1,
  renderer: renderer,
}));


function SiteTopicContent({ content, isMobile, theme }) {
  const {
    attributes: {
      topic_type: topicType,
    },
  } = content;

  const {
    content: {
      widgets = [],
    },
  } = relationships(content);

  const isCard = topicType === 'card';
  const isUgc = topicType === 'ugc';
  const CardIndentWrapper = isCard ? 'div' : EmptyWrapper;
  const headlines = widgets
    .filter(widget => widget.type === 'pageBreak')
    .map(block => block.attributes.title);

  const ctx = isUgc ?
    { lastMarkdownId: getLastMergedMarkdownId(widgets) }
    : null;

  return (
    <div className={isCard && styles.topicCard}>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.primary}
      `}</style>
      {isCard && (
        <ol className={styles.listTitle}>
          {headlines.map(title => (
            <li key={title} className={styles.titleItem}>
              <a href={'#' + title} className={styles.title}>{title}</a>
            </li>
          ))}
        </ol>
      )}
      {!isCard && <Indent bottom={isMobile ? 20 : 30} />}
      <CardIndentWrapper className={styles.cardIndentWrapper}>
        <TopicContent
          ctx={ctx}
          content={widgets}
          interitemSpacing={isMobile ? 15 : 20}
          {...{
            blocks,
            mobileAds,
            desktopAds,
          }}
          {...isCard && { disableMultipage: true }}
        />
      </CardIndentWrapper>
    </div>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(SiteTopicContent));
