import color from 'core/libs/color';
import baseColors from './colors';
import Logo from 'site/components/Logo';
import withBindProps from 'core/hocs/withBindProps';

const LogoDark = withBindProps({ type: 'dark' })(Logo);

const colors = {
  ...baseColors,
  primary: '#fff', // Основной цвет, текст
  layout: baseColors.text, // цвет фона body
  content: baseColors.text, // цвет фона контентной области
  active1: '#fff', // Доп. цвет, выделение, ссылки
  accent: '#888a0f', // Выделение UGC
  divider: 'rgba(255, 255, 255, 0.3)',
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() { // цвет темы, meta theme-color, tile-color, safari-pinned-tab
    return this.active1;
  },
};

const link = {
  tertiary: {
    hover: {
      decoration: 'underline',
    },

    visited: {
      color: colors.primary,
    },
  },
};

const bullet = {
  const: {
    size: '8px',
  },

  basic: {
    idle: {
      background: color(colors.primary).alpha(0.6).string(),
    },

    hover: {
      background: color(colors.primary).alpha(0.8).string(),
    },

    active: {
      background: colors.primary,
    },
  },
};

const button = {
  secondaryDark: {
    idle: {
      color: colors.hint,
      background: colors.primary,
      border: `1px solid ${colors.hint}`,
    },
    hover: {
      color: '#000',
      background: colors.primary,
      border: '1px solid #000',
    },
    active: {
      color: colors.content,
      background: colors.primary,
      border: `1px solid ${colors.content}`,
    },

    disabled: {
      color: colors.content,
      background: colors.primary,
      border: `1px solid ${colors.content}`,
    },
  },
};


export default {
  colors,
  icons: {
    logo: LogoDark,
  },
  controls: {
    link,
    bullet,
    button,
  },
};
