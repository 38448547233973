import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';
import withTheme from 'core/hocs/withTheme';

import Feed from 'core/components/Feed';
import EmptyWrapper from 'core/components/EmptyWrapper';
import Desktop from 'core/components/breakpoint/Desktop';
import FullWidth from 'core/components/FullWidth';
import AdWrapper from 'core/components/Ad/AdWrapper';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import ListHeader from 'site/components/ListHeader';
import ListDivider from 'site/components/ListDivider';
import { Indent } from 'site/components/Wrappers';

import { Context } from 'site/components/Ads/desktop';

import {
  CardVertical1M,
  CardVerticalS,
  CardVertical1S,
} from 'site/cards/CardVertical';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

const HeaderComponent = withBindProps({ level: 2 })(ListHeader);
const MobileCard = withBindProps({
  card: CardVertical1M,
  indent: VERTICAL_INDENT_MOBILE,
})(ListDivider);
const blockTitle = 'Истории читателей';


function UserStories(props) {
  const {
    content,
    isMobile,
    isDesktop,
    theme,
  } = props;

  if (!content || !content.length) return null;

  const firstThree = content.slice(0, 3);
  const others = content.slice(3);
  const vertical = isMobile ? 20 : VERTICAL_INDENT;
  const mobileTitle = <div className={styles.mobileTitle}>{blockTitle}</div>;

  others.forEach(topicData => delete topicData.relationships.image);

  return (
    <EmptyWrapper>
      <Desktop>
        <style jsx>{`
          :global(.${styles.divider})
            border 1px solid ${theme.colors.primary}
        `}</style>
      </Desktop>
      {isDesktop &&
        <FullWidth shift={isMobile ? 0 : 165}>
          <ListDivider />
        </FullWidth>
      }
      <Indent bottom={vertical} />
      <Feed
        title={isMobile ? mobileTitle : blockTitle}
        headerComponent={HeaderComponent}
        content={firstThree}
        card={isMobile ? CardVertical1M : CardVerticalS}
        interitemSpacing={isMobile ? 20 : 30}
        columns={3}
      />
      <ListDivider
        indent={20}
        {...isDesktop && { className: styles.divider }}
      />
      <Feed
        content={others}
        card={isMobile ? MobileCard : CardVertical1S}
        interitemSpacing={isMobile ? 0 : 30}
        columns={2}
        grid
      />
      <Indent bottom={vertical} />
      {isDesktop &&
        <FullWidth shift={isMobile ? 0 : 165}>
          <ListDivider />
        </FullWidth>
      }
      <AdWrapper bottom={vertical}>
        <Desktop>
          <Context />
        </Desktop>
      </AdWrapper>
    </EmptyWrapper>
  );
}

UserStories.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(UserStories));
